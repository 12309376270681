@import "../base/variables";

.footer-container {
    width: 100% !important;
    padding-right: 3rem !important;
}
.footerwrap {
    display: flex;
    width: 100% !important;
    align-items: center;
    background: #edf6fd87;
    border-radius: 10px;
    justify-content: space-between;
    padding: 15px 25px;
    border: 1px solid #9abdd250;

    .copyright-wrap {
        display: flex;
        font-size: 12px;
        align-items: center;
    }

    .footer-logo {
        width: 120px;
        margin-right: 15px;
    }

    .footer-menu {
        display: flex;
        list-style: none;
        margin-bottom: 0;

        li { 
            margin-left: 20px;
            a {
                font-size: 14px;
                color: #444;
                text-decoration: none;
            }
        }
    }
}

.absolute-footer{
    position: absolute;
    bottom: 30px;

    .footer-menu {

        padding: 10px 15px;
        border-radius: 10px;
        display: flex;
        list-style: none;

        li {
            padding: 0 15px;
            a {
                color: #31416a;
                font-size: 14px;
                text-decoration: none;
            }
        }
    }
}