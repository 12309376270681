@font-face {
  font-family: "Interstate", serif;
  font-style: normal;
  font-weight: normal;

  src: url("../../assets/font/interstate-black-comp-58b64117a2ad9.otf"),
    url("../../assets/font/interstate-black-cond-58b642507872b.otf"),
    url("../../assets/font/interstate-bold-cond-58b64162258ca.otf"),
    url("../../assets/font/interstate-extralight-italic-58b642045546b.otf"),
    url("../../assets/font/interstate-light-cond-58b64220b1490.otf"),
    url("../../assets/font/interstate-light-italic-58b64147957e5.otf"),
    url("../../assets/font/interstate-regular-comp-58b641cc0aca1.otf"),
    url("../../assets/font/interstate-regular-italic-58b641ecad88f.otf"),
    url("../../assets/font/interstate-ultrablack-comp-58b64130a8cbf.otf");
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@import "./variables";

// @import url('https://fonts.cdnfonts.com/css/interstate');

// ==========================================
// ================= GLOBAL =================
// ==========================================

body {
  background-color: $secondary !important;
  overflow-x: hidden;
  // font-family: 'Overpass', sans-serif !important;
  font-family: "Interstate", sa !important;
  // font-weight: 200 !important;
  color: $dark;
  position: relative !important;

  .alert {
    position: fixed !important;
    top: 40%;
    left: 50%;
    transform: translateY(-20%);
    transform: translateX(-50%);
    z-index: 10000;
    min-height: 10rem;
    width: 50vw;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.container-fluid {
  padding: 60px !important;
  font-family: "Interstate", sans-serif !important;
}

main {
  padding: 5rem;
  margin-top: 6rem !important;
  overflow: hidden !important;
  font-family: "Interstate", sans-serif !important;

  .scroll-section {
    height: calc(100vh - 14rem) !important;
    overflow-y: scroll !important;
    width: 100% !important;

    .form-container {
      box-shadow: $shadow !important;
    }

    .card {
      border-radius: $radius !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-buttons {
    .buttons-container {
      box-shadow: 0px -5px 20px rgba(52, 65, 103, 0.3);
      z-index: 100;
      margin-top: 0.5rem;
    }
  }
}

.sm-text {
  font-size: 12px !important;
}

.md-text {
  font-size: 20px !important;
}

.lg-text {
  font-size: 35px !important;
}

.logo {
  width: 165px !important;
}
.toolTip {
  background-color: $dark;
  color: $light;
  max-width: 30rem;
  z-index: 1000;
}

.form-control,
.form-select {
  border-color: $info !important;
  border-width: 1px !important;

  select:hover {
    background-color: red !important;
  }
}

.modal {
  position: relative;

  .modal-dialog {
    box-shadow: $shadow !important;
    background-color: transparent !important;
    border-radius: 8px !important;
    .modal-content {
      .modal-body {
        .machine-title {
          max-width: 6.5rem !important;
        }
        .total-cost-holder {
          .price-label {
            font-weight: 700;
            color: $dark;
          }
        }
        .value-text {
          color: $dark;
          font-weight: 600 !important;
        }
      }
    }
  }
}
.text-with-border {
  color: $dark;
  font-weight: 600;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 6rem;
    height: 2px;
    left: 0;
    bottom: -4px;
    background-color: rgb(210, 184, 195);
    opacity: 0.6;
  }
}
.modal::before {
  content: "";
  background: #ffffff8d !important;
  opacity: 0.75;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.protectType-card {
  height: 38rem !important;
  min-height: 38rem !important;
  border-radius: 0 !important;
  .card-header {
    height: 6rem !important;
    min-height: 6rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light !important;
    border-radius: 0 !important;
    border: 0 !important;
    p {
      background-color: $light;
      padding: 0.5rem;
      border-radius: 3px !important;
    }
  }

  .card-body {
    border: 0 !important;
    span {
      color: $dark !important;
    }
  }

  .card-footer {
    border: 0 !important;
    padding: 2rem 0 !important;
    background-color: $white !important;
    .btn {
      outline: 2px solid $dark !important;
      padding: 1rem !important;
      border-radius: 0 !important;
      border: none;
      &.checked {
        outline: 0 !important;
        background-color: $dark !important;
      }
    }
  }
}

.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 24px;
  height: 24px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 6px;
  border: 2px solid #89a0d3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #89a0d3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-input-placeholder {
  font-size: 25px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 25px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 25px;
}

/* Overriding styles */

::-webkit-input-placeholder {
  font-size: 13px !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px !important;
}

.p-calendar {
  .p-component {
    line-height: unset !important;
    padding: 3px;
    background: $light;
    border: 2px solid $info;
    border-radius: 5px;
  }
}
