@import "../base/variables";

.admin-contracts-list {
  border-radius: 0;

  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  .contracts-list {


    .list-header {
      border: none;
      border-bottom: 1px solid $primary;
      background: none;
      color: #344167;
      font-size: 15px;
      margin-bottom: 30px;
      margin-top: 20px;
      // padding: 5px 35px 5px 35px;
    }
    .list-item {
      border-radius: 10px;
      background: #edf6fd;
      min-height: 150px;
      box-shadow: 0 10px 20px #34416730;
      margin-bottom: 25px;
      display: flex;
      flex-wrap: nowrap;
      span {
        font-size: 14px;
        color: #30426a;
        display: flex;
        align-items: center;
        height: 100%;
      }
      .select {
        width: 220px;
        margin: 0 0 0 15px;
      }
      .edit-btn {
        font-size: 12px;
        color: #30426a;
        border: 2px solid #9abdd2;
        border-radius: 10px;
        padding: 4px 7px;
      }
      .button-area {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
      }
    }
  }
}
