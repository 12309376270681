.dashboard-container {
    position: relative;
    min-width: 1100px;
}
.admin-contracts-list {
    width: 100%;
    overflow: auto;
    .list-group-item {
 
     }
}
.button-area {
    display: flex;
    justify-content: flex-end;
    & > div {
        margin-left: 10px;
    }
}
.admin-contracts-list .list-item .select {
    margin: 0;
}
.mv-100 {
    min-width: 100%;
}
.search {
    display: flex;
    justify-content: flex-end;
}

.dash-bottom .icon-cell {
    text-align: right;
}
.contracts-list .list-item {
    padding: 5px 20px 5px 20px;
}
.admin-contracts-list {
    min-width: auto;
    height: auto;
}
.side-bar .avatar img {
    background: transparent;
    border: 1px solid #bfc0bf;
}
.calculator-page .form-container .inner-form-container {
    height: auto;
    /*form {
        padding: 1rem
    }*/
}
.calculator-page .form-container .calculator-footer{
    height: auto;
    position: sticky;
    bottom: 0;
    box-shadow: 0 0px 15px #31416a6e !important;
    border-radius: 0;
}
.calculator-page .form-container {
    overflow-x: hidden;
}
.pr-0 {
    padding-right: 0;
}
.admin-contracts-list .contracts-list .list-item .select {
   margin: 0;
}
.head-btns {
    margin-bottom: 20px;
    .btn{
        background: transparent;
        border: 0;
        font-size: 16px;
        white-space: nowrap;
        color: #31416a;
        position: relative;
        padding: 0;

        span {
            color: #31416a;
        }

        &::before {
            content: "|";
            padding-right: 5px;
            padding-left: 5px;
        }
        &:first-child{
            &::before {
                display: none;
            }
        }
        &.active {
            span {
                color: #AE1228;
            }
            color: #AE1228;
        }
        
    }
   
}
.admin-contracts-list .contracts-list .list-item .edit-btn {
    .bear-icon {
        display: none;
    }
}

.err-shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

@media screen and (max-width: 1380px) and (min-width: 1200px) {
    .custom-size {
        //background: #ccc;
        .col-xl-3 {
            width: 100%;
        }
        .col-xl-2 {
            width: 20%;
            margin-top: 1.5rem !important;
            margin-bottom: 1.5rem !important;
        }
        .col-xl-7 {
            width: 80%;
            margin-top: 1.5rem !important;
            margin-bottom: 1.5rem !important;
        }
    } 
}
@media screen and (max-width: 1305px) and (min-width: 1200px) {
    .protectType-card {
        min-height: 40rem !important;
    }
}
@media screen and (min-width: 1200px) {
    .calculator-page .form-container {
        width: 98%;
    }
   
}

@media screen and (max-width: 1200px) {
    .side-bar {
       padding: 15px;
     }
     .protectType-card {
        min-height: auto !important;
        height: auto !important;
    }
    .admin-contracts-list .contracts-list .list-item .edit-btn {
        border: 0;
        padding: 0;
        .bear-icon {
            display: block;
            svg {
                width: 22px;
                height: 22px;
            }
        }
        .bear-text {
            display: none;
        }
    }
}
@media screen and (max-width: 1400px) {
    .dash-bottom{
        .col-lg-5, .col-lg-7{
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .search .input-group {
        width: auto;
    }
}
@media screen and (max-width: 1365px) {
    .container-fluid {
        padding: 30px 15px !important;
    }
}


