@import "../base/variables";

.side-bar {
    background-color: #EDF6FD;
    border-radius: 10px;
    box-shadow: $shadow;
    min-height: 60vh;
    padding: 40px;
    text-align: center;
    position: sticky;
    top:15px;

    .side-logo {
        text-align: center;
        img {
            width: 80%;
        }
    }

    .avatar {
        text-align: center;
        position: relative;
        width: 100%;
        margin: 50px 0 20px;
        
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }

        .upload {
            width: 100%;
            position: absolute;
            object-fit: contain;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: none;
            align-items: center;
            justify-content: center;

            svg {
                width: 100%;
                fill: #30426a !important;
            }
        }
        .btn {
            background: #ffffff90;
             border-radius: 50%;
            width: 50px;
            height: 50px;
            padding: 10px;
        }

        &:hover {
            .upload {
                display: flex;
            }
        }

    }

    .fullName {
        font-family: "Interstate", sans-serif;
        font-size: 32px;
        color: #30426A;
        font-weight: 400;
        margin-bottom: 0;
    }

    .jobRole {
        font-size: 15px;
        font-family: "Interstate", sans-serif;
        color: #30426A;
        font-weight: 400;
    }

    .list-group {
        text-align: left;
        margin: 30px 0;
        font-family: "Interstate", sans-serif;
        font-weight: 400;
        li {
            list-style: none;
        }
        svg {
            width: 33px !important;
            height: 33px !important;
            margin-right: 15px;
            fill: #30426a !important;
        }
        .nav-link {
            background-color: $light;
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #30426A;
            border-bottom: 1px solid #34416730;
            padding: 10px 15px;
            i {
                font-size: 33px;
                margin-right: 25px;
            }
          
            &.active {
                color: #AE1228;
                svg {
                    fill: #AE1228 !important;
                }
            }
        }

    }

    .btn {
        padding: 8px 15px;
    }
    .logout-btn {
        width: 100%;
        text-align: left;
        margin-top: 50px;
        svg {
            width: 33px !important;
            height: 33px !important;
            margin-right: 15px;
        }
    }
}