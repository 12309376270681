// ==========================================
// ================= Bonus Cart =============
// ==========================================
@import '../base/variables';

$card-border-color: $primary;
$card-box-shadow: $shadow;
$card-margin-top: 2.8rem;

.wide-shadow {
  margin-bottom: 100px;
  &-active {
    margin: -50px;
    height: 100px;
    background-image: linear-gradient(
      rgb(255 255 255 / 0%) 10%,
      #dce8f6 40%,
      #dce8f6 20%
    );
    i {
      font-size: 24px;
      color: #222;
    }
  }
  i {
    font-size: 24px;
    color: #222;
  }
}
.bonus-card {
  // width: 22rem !important;
  // min-width: 22rem !important;
  
  max-width: 22rem !important;
  width: 22rem !important;
  height: 40rem !important;
  overflow: hidden;
  padding: 31px !important;
  margin-top: $card-margin-top !important;
  $shadow: $shadow;
  position: sticky !important;
  bottom: 15rem !important;
  right: 2rem !important;
  @media screen and (max-width:1400px) {
    background-color: red !important;
    max-width: 22rem !important;
  width:18rem !important;
  }
  // bottom: 3rem !important;
  .card-header {
    
    p {
      font-size: 18px;
      font-weight: 800 !important;
      color: $dark;
    }
    // margin: auto 1rem;
  }

  .card-body {
    height: 30rem !important;
    overflow-y: scroll;
    .cart-item {
      padding: 1rem;
      position: relative;

      .item-details {
        margin-top: 1rem !important;
      }

      .item-cost {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
      }
    }
  }

  .card-footer {
    // padding: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    .price-label {
      font-size: 14px !important;
      text-align: start !important;
      margin: auto 1rem;
      color: $dark;
      font-weight: 800 !important;
    }

    .total-price {
      font-size: 26px !important;
      color: $dark;
      padding-top: 2.5rem !important;
      font-weight: 200 !important;
    }
  }
}
