@import './base/base';
@import './base/variables';

// components
@import './components/navbar';
@import './components/bonus-card';
@import './components/products-gallery';
@import './components/button';
@import './components/check-box';
@import './components/user-card.scss';
@import './components/table-card';
@import './components/pdf-modal';
@import './components/_page-header';
@import './components/_footer';
@import './pages/dashboard';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import './components/_emailform';

// pages
body {
  font-family: 'Interstate', sans-serif !important;
  font-weight: 400 !important;
  color: #30426a;
}

.loading {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@import './pages/login';
@import './pages/distributors';
@import './pages/admin-contracts';
@import './pages/contracts-list';
@import './pages/contract-details';
@import './pages/my-contracts-page';
@import './pages/calculator';
@import './pages/payment';
@import './pages/allpayment';
@import './pages/hilfe';
@import './pages/distform';
@import './base/_reponsive';

.mantine-DatePickerInput-day[data-today="true"] {
  background-color: #ae1228 !important;
  border-radius: 50% !important;
  color: white !important;
}



.mantine-DatePickerInput-monthCell {
  border-radius: 50% !important;
  padding: 0 !important;
}

.mantine-Popover-dropdown{
  width: 360px !important;
}