@import "../base/variables";

.contracts-list {
    border-radius: 0;

    .list-header {
        border: none;
        border-bottom: 1px solid $primary;
        background: none;
        color: #344167;
        font-size: 15px;
        margin-bottom: 30px;
        margin-top: 20px;
        padding: 5px 35px 5px 35px;
    }

    .list-item {
        border-radius: 10px;
        background: #EDF6FD;
        box-shadow: 0 10px 20px #34416730;
        display: flex;
        margin-bottom: 25px;
        min-height: 120px;
        height: 120px;
        align-items: center;
        padding: 5px 35px 5px 35px;
        align-items: center;
        span {
            font-size: 14px;
            color: #30426A;
            display: flex;
            align-items: center;
            height: 100%;
        }
        // .select {
        //     width: 200px;
        //     margin: 0 0 0 15px;
        //     z-index: 1000;
        // }
        .edit-btn {
            font-size: 12px;
            color: #30426A;
            border: 2px solid #9ABDD2;
            border-radius: 10px;
            padding: 4px 7px;
        }
    }
}