@import "../base/variables";
.page-header {
 .btn-group {

    display: flex;
    align-items: center;

    .btn {
        font-size: 17px !important; 
     }

     display: flex;
     
 }
}
.page-title-holder {
    span {
        color: #AE1228;
    }
    .btn {
        font-size: 16px !important;
    }
}
.search {
   .input-group {
        border: 2px solid #9ABDD2;
        border-radius: 10px;
        background: #DBE9F6;
        height: 48px;
        display: flex;
        align-items: center;
        width: 419px;
        padding: 0 15px;

        .input-group-text {
            background: none;
            border: none;
            font-size: 14px;
            padding: 5px;
            
        }
        svg {

        }
        .form-control {
            background: none;
            border: 0;
            padding: 5px 15px;
            &:focus {
                box-shadow: none;
            }
        }
   }
}   