@import '../base/variables';

.pdfModal {
  .machine-title {
    border-bottom: 1px solid $primary;
    width: 7rem;
    padding-bottom: 0.5rem;
  }

  .summary-section {
    height: 10rem;
    align-items: flex-start;

    img {
      margin-right: 0.5rem;
    }

    .total-container {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0.25rem;

      .total-price {
        font-size: 30px;
        font-weight: normal;
      }
    }
  }

  .semi-bold-text {
    font-weight: 400 !important;
  }
}
