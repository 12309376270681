.emailform{
    background: #ffffff;
    position: absolute;
    display: block;
    height: 0;
    overflow: hidden;
    transition: all .3s;
    z-index: 999;
    //padding: 30px;
    

    &.open {
        height: 100%;
    }
    .page-content {
        padding: 30px 0;
    }
    .alert-content {
        font-size: 18px;
        font-weight: 600;
        margin-bottom:15px;
        color: #ad1328;
    }
    .btn-close {
        position: absolute;
        right: 15px;
        top:15px;
    }
}