@import '../base/variables';

.contract-details-holder {
  .contract-details {

    &.card {
      background: #EDF6FD;
      border: none;
      border-radius: 10px;
      box-shadow: 0 10px 20px #00000016;
    }
    .card-header {
      background: none;
      border: none;
      margin-top: 40px;
    }

    .machine-title {
      border-bottom: 1px solid $primary !important;
      font-size: 20px;
      padding-bottom: 0.75rem;
      display: inline;
    }


    .contact-info {
      height: 10rem;
      display: flex;
      align-items: center;
      img{
        width: 300px;
        height: 150px;
        object-fit: cover;
      }
      .total-cost-holder {
        height: 100%;
      }
    }
  }
}
