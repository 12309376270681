.login-page {
  background-image: url(../../assets/images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(219 233 246 / 90%);
  }

  .login-col {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-card {
    }
  }

  // background-color: #EDF6FD !important;
  .logo-img {
    width: 10rem;
    display: block;
    margin: 0 auto;
  }

  .big-logo {
    position: relative;
    width: 100%;
    padding: 10px 50px;
    margin-bottom: 30px;
    img {
      //width: 30%;
    }
  }
  .login-form {
    margin-bottom: 100px;
    background: #edf6fd;
    padding: 30px;
    box-shadow: 0 10px 20px #34416730;
    width: 430px;
    position: relative;
    border-radius: 10px;
    font-family: "Interstate", sans-serif;
    font-size: 17px;
    h1 {
      font-size: 35px;
      color: #344167;
    }
    .form-control {
      border: 2px solid #9abdd2 !important;
      padding: 8px 15px;
      border-radius: 10px;
      // width: 100%;
      background: #edf6fd;
    }

    .form-label {
      color: #344167;
      font-weight: bold;
      font-size: 17px;
    }

    .btn {
      color: #dbe9f6;
      background: #30426a;
      font-size: 17px;
      padding: 10px;
      width: 100%;
      border-color: #30426a;
      &:hover{
        background: #344167 !important;
        border-color: #344167;
      }
    }
    .p-password-input {
      width: 100% !important;
      background-color: $light;
    }
    .p-inputwrapper i{
      line-height: 1rem !important;
    }
    .forgot-password-wrap {
      text-align: right;
      padding-top: 5px 0 25px 0;
      a {
        color: #344167;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
