@import "../base/variables";

.calculator-page {
  .bonus-card {
    position: absolute !important;
    bottom: 5rem;
    margin-left: 30px;
    min-height: 300px !important;
    height: 30rem !important;
    margin-top: 0px !important;
    box-shadow: 0px 10px 20px rgb(52 65 103 / 29%);
    border-radius: 9px;
    padding: 1rem !important;
  }

  .header {
    height: 70px;
    background: #edf6fd;
    box-shadow: 0px 10px 20px rgba(52, 65, 103, 0.3);

    .inner-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 80rem;
      position: absolute;
      /* left: 50%;
            transform: translateX(-50%); */
            left: 60%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media screen and (min-width:1100px) {
        left: 60%;
      }
      .nav-btn {
        position: relative;
        font-size: 14px !important;
        display: flex;
        align-items: center;
        padding-right: 15px !important;
        min-width: 172px;
        margin-right: 30px;
        border-radius: 10px;
        border: 2px solid #30426a !important;
        max-height: 40px;
        white-space: nowrap;
        span {
          font-size: 22px;
          margin-right: 20px;
          margin-left: 8px;
        }
        &:not(:last-child)::before {
          content: "";
          position: absolute;
          height: 2px;
          width: 2.7rem;
          top: 50%;
          left: 100%;
          background: $dark;
        }
      }
    }
  }

  .calculator-content {
    margin-top: 5rem !important;
    height: 100%;
    overflow: hidden;
    
  }

  .form-container {
    
    position: relative;
    height: 55rem !important;
    max-height: 55rem !important;
    overflow: scroll;
    width: 700px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    // overflow: hidden;
    @media screen and (min-width:1200px) {
      width: 800px;
    }
    @media screen and (min-width:1400px) {
      width: 100%;
    }
    .inner-form-container {
      // max-height: 50rem !important;
      height: 70rem;
      overflow: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (max-width:1200px) {
        height: 90rem;
      }
    }

    .calculator-footer {
      position: absolute;
      bottom: -5rem;
      width: 100%;
      z-index: 999;
      height: 125px;
      background-color: #edf6fd !important;
      //background-color: $light !important;
      // box-shadow: $shadow !important;
      box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
        rgb(0 0 0 / 30%) 0px 3px 7px -3px !important;
      .inner-footer-container{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }
      @media screen and (max-width:1200px) {
        bottom: -10rem;
        height: 200px;
      }
    }

    .categories-holder {
      position: relative;
      .scroll-btn {
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);

        &#right {
          right: 0px;
        }
        &#left {
          left: 0px;
        }
      }
      .categories-list {
        // height: 10rem;
        overflow: scroll;
        scrollbar-width: none;
        padding: 1rem 2rem;
        &::-webkit-scrollbar {
          display: none;
        }
        .category-card {
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 20rem;
          height: 20rem;
          cursor: pointer;
          min-width: 15rem;
          flex-shrink: 0;
          position: relative;

          .selected-sign {
            background-color: $primary;
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -15%);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border-radius: 50%;
          }

          .category-title {
            min-height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            // background: rgba(255, 255, 255, 0.2);
            // // border-radius: 16px;
            // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            // backdrop-filter: blur(5px);
            // -webkit-backdrop-filter: blur(5px);
            // border: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
      }

      .grey-shadow-holder {
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(
          269.98deg,
          #edf6fd 0.02%,
          rgba(237, 246, 253, 0.0001) 99.98%
        );
        width: 8rem;
        height: 100%;
      }
    }
  }

  .machines-previews {
    background-color: transparent !important;
    .machine-preview {
      position: relative;
      padding: 0 !important;
      box-shadow: $shadow;
      margin-bottom: 3rem !important;
      .hidden-section {
        overflow: hidden;
        height: 0;
        transition: all 0.3s;

        &.show {
          height: 842px !important;
        }
      }
    }

    .machine-preview-shadow {
      position: absolute;
      bottom: 0rem;
      left: 0;
      height: 4rem;
      width: 100%;
      background: linear-gradient(
        269.98deg,
        #edf6fd 0.02%,
        rgba(237, 246, 253, 0.0001) 99.98%
      ) !important;
      opacity: 0.5;
    }
  }

  .form-control-sm {
    border: 2px solid #9abdd2 !important;
    padding: 5px 10px;
    border-radius: 6px;
    color: #344167;
  }
  .form-select-sm {
    border: 2px solid #9abdd2 !important;
    padding: 5px 10px;
    border-radius: 6px;
  }
  .datearea {
    align-items: baseline;
    justify-content: flex-end;
    p {
      margin-bottom: 0;
    }
  }
}
