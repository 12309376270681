$row-height: 55px;
$font-size: 12px;

.table-card {
    box-shadow: $shadow;

    .card-body {
        border: none;


        .table {
            tr {
                height: $row-height;
                border-bottom: 1px solid $primary;

                td {
                    font-size: $font-size !important;
                }

                .icon-cell {
                    font-size: $font-size !important;
                }

                &:last-child {
                    border: none;
                }
            }

        }
    }
}