@import "../base/variables";
.small-text {
  font-size: 15px !important;
}

body{
  position: relative;
  .editSide{
    position: fixed;
    overflow-y: scroll;
    right: 0;
    top: 0;
    height: 100%;
    width: 30rem;
    z-index: 20;
    background-color: $alice-blue;
    border-left: 2px solid $dark;
  }
}
.distributor-card {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 10px 20px #34416730;
  padding: 30px;
  background: #edf6fd;
  font-size: 20px;
  font-family: 'Interstate', sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
  min-height: 450px;
  height: 500px;
  position: relative;
  .card-header {
    background: none;
    padding: 0;
    border: none;
    
    h5 {
      color: #ae1228;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    span {
      font-size: 18px;
    }
    .dropdown{
      position: absolute;
      right: 0;
      top: 0;
    }
    .distributor-state{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .state-circle{
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      p{
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .card-body {
    background: none;
    padding: 0;
    border: none;
    .label-text {
      font-size: 16px;
      label {
        color: #91bed4;
        font-size: 14px;
      }
    }
    .income-value {
      font-size: 24px;
      margin-top: 0;
      font-weight: 700;
      color: $dark;
    }
  }
  .card-footer {
    background: none;
    padding: 4px;
    border: none;
    border-top: 1px solid #ae1228;
    .links {
      justify-content: space-between;
      a {
        font-size: 12px;
        color: #91bed4;
        text-decoration: none;
      }
    }
    .btn {
      // border: 1px solid #9abdd2;
      border-radius: 10px;
      background: #dbe9f6;
      font-size: 12px;
      // height: 42px;
      display: flex;
      align-items: center;
      svg {
        margin: 0 3px;
        width: 16px;
      }
    }
  }
}
