@import '../base/variables';
// ==========================================
// ================= NAVBAR =================
// ==========================================
$navbar-padding-y: $spacer * 1;
// $navbar-padding-x: 60px;
$navbar-nav-link-padding-x: 1rem;
$navbar-margin-end: 2rem;
$navbar-nav-link-border-width: 3px;

.navbar {
  background-color: $light;
  height: 5.75rem !important;
  position: relative;

  .logo {
    width: 4rem !important;
    margin-right: 3rem !important;
    // position: absolute;
    // top: 0;
    // left: 60px;
    // margin-top: 10px;
  }

  .navbar-nav {
    .nav-item {
      width: 246px !important;
      font-size: 12px !important;
      margin-right: 3rem;

      &.active {
        background-color: $dark;
        border-radius: 4px;

        .nav-link {
          color: $light !important;
        }
      }

      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;

        .label-index {
          font-weight: bolder;
        }
      }

      .dash {
        width: 3rem;
        height: 1px;
        background-color: $dark;
        right: -3rem !important;
        top: 50% !important;
      }
    }
  }

  box-shadow: $shadow-bottom;
}