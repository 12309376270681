$product-card-radius: 10px;

.products-gallery {
  // max-width: 1150px;
  overflow: scroll;
  padding: 0 !important;

  &::-webkit-scrollbar {
    display: none;
  }

  .product-card {
    border-radius: $product-card-radius;
    margin-right: 10px;
    height: 251px;
    width: 226px;
    border: 1px solid $dark;
    position: relative;
    cursor: pointer;
    border-radius: $product-card-radius;

    .product-content {
      background: linear-gradient(
        to bottom,
        rgba(113, 12, 25, 0.3),
        rgba(113, 12, 25, 0.647)
      );
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      border-radius: $product-card-radius;

      .check-sign {
        // color: $light;
        // font-size: 2rem;
        background-color: $primary;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 2rem;
      }
    }

    .product-footer {
      background-color: $dark;
      height: 57px;
      position: absolute;
      bottom: 0;
      width: 100%;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: $product-card-radius;
      border-bottom-right-radius: $product-card-radius;

      .product-title {
        color: $light;
        text-align: center;
        font-size: 14px;
      }
    }

    &.selected {
      border-color: $primary;

      .product-content {
        display: flex;
      }

      .product-footer {
        background-color: $primary;
      }
    }
  }
}
