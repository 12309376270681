@import '../base/variables';

.main-content {
  
  .welcome-message {
    color: #30426a;
    font-family: 'Interstate', sans-serif;
    font-weight: 400;
    h3 {
      margin-bottom: 0;
    }
  }
  .header {
    .today-calender {
      svg {
        margin-right: 15px;
      }
      box-shadow: $shadow;
    }
  }
  
  .info-card {
    box-shadow: $shadow;
    border-radius: 10px !important;
    background: #edf6fe;
    border: 0;
    height: 20rem !important;
    margin-bottom: 30px;
    box-shadow: 0 10px 20px #34416730;

    .card-header {
      border: none;
      background: none;
      border-radius: 10px !important;
      font-size: 22px;
      color: $dark;
      font-weight: 400;
    }
    
    .card-body {
      padding: 30px;
      .lead {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3; 
        -webkit-box-orient: vertical;
        font-size: 16px;
      }
    }

    .card-icon {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    .body-text {
      // font-size: 64px;
      font-size: 48px;
      color: $dark;
      font-weight: 400;
      letter-spacing: 1px;
      margin-bottom: 1rem;
    }

    .footer-text {
      // font-size: 30px;
      font-size: 22px;
      color: $dark;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  .top-three-section {
    // box-shadow: $shadow;
    .info-card {
      min-height: 30rem;

      img {
        width: 100%;
        height: 12rem;
        object-fit: cover;
      }
    }
  }

  .company-card {
    .card-header {
      .company-title {
        font-size: 20px;
        color: beige;
      }
    }

    .company-state {
      position: relative;
      font-size: 12px;

      .state-circle {
        width: 20px;
        height: 20px;
      }
    }
  }

  .height-auto {
    min-height: auto !important;
    height: auto !important;
  }
}
