$check-box-height: 3.5rem;
$check-icon-width: 1.8rem;
$check-icon-height: 1.8rem;
$check-label-font-size: 12px;

.check-input-container {
    border: 2px solid $info;
    border-radius: $radius;
    cursor: pointer !important;
    height: $check-box-height;

    input[type="checkbox"] {
        visibility: hidden;
    }

    &.checked {
        .check-icon {
            display: unset;
        }

        background-color: $dark;
        color: $light;

        .check-icon {
            border-color: $info;
            background-color: $dark;
        }
    }


    .check-input-label {
        font-size: $check-label-font-size;
        cursor: pointer !important;
    }

    .check-icon {
        width: $check-icon-width;
        height: $check-icon-height;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $info;
        display: none;

        i {
            color: $info;
        }
    }
}