.my-contracts-page {
    .my-contracts-list {
        .list-group-item {
            .last-col {
                width: 20rem !important;
            }

            .controls {
                .dropdown {

                    .dropdown-toggle {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }

                    .state-circle {
                        width: 1.2rem;
                        height: 1.2rem;
                        // background-color: $primary;
                        border-radius: 50%;
                    }

                    .dropdown-menu {
                        .dropdown-item {
                            cursor: pointer !important;
                        }
                    }
                }

            }
        }
    }
}