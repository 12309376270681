
.distform{
    position: relative;
    .card {
        border-radius: 10px;
        border:none;
        box-shadow: 0 10px 20px #34416730;
        padding: 30px;
        background: #EDF6FD;
    }
    .btn {
        width: 300px;
        margin: 0 auto;
        padding: 7px 25px;
    }
}