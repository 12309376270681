.payment {
  h3 {
    font-size: 20px;
    color: #ae1228;
  }
  .card {
    margin-top: 30px;
    padding: 30px 40px;
    background: #edf6fd;
    box-shadow: 0 10px 20px #34416730;
    border: 0;
  }
  .timeline {
    width: 100%;
    border-left: 2px solid #344167;
    list-style: none;
    position: relative;
    padding: 0;
    margin-top: 15px;

    li {
      position: relative;
      padding: 0 0 0 40px;
      .date {
        color: #789aaf;
        font-size: 16px;
        display: block;
        margin-top: -5px;
      }
      &::after {
        content: ' ';
        position: absolute;
        left: -6px;
        top: 5px;
        background: #000;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      .prices {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 40px;

        .child {
          display: flex;
          flex-direction: column;
          label {
            color: #91bed4;
            font-size: 12px;
          }
          .price {
            font-size: 25px;
            color: #30426a;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .price-up {
        background: url(../../assets/images/priceUp.svg) center center no-repeat;
        background-size: inherit;
        width: 100%;
        height: 100%;
      }
      .price-down {
        background: url(../../assets/images/priceDown.svg) center center
          no-repeat;
        background-size: inherit;
        width: 100%;
        height: 100%;
      }
      .price-same {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .form-label {
    color: #91bed4;
    font-size: 12px;
    display: block;
  }
  .form-control {
    background: #edf6fd;
    padding: 5px 15px;
    display: block;
  }
  .form-button {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 5px 15px;
      color: #dbe9f6;
      font-weight: 400;
    }
  }
  select {
    width: 100%;
    height: 35px;
    border: 1px solid #9abdd2;
    border-radius: 5px;
    padding: 0 10px;
    background: #edf6fd;
  }
}
