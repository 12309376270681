.allpayment {
    .buttons {
        .btn{
            color: #DBE9F6;
            background: #344167;
            padding: 7px 25px;
            padding: 8px 25px;
            font-size: 15px;
            border-radius: 10px;
            margin-right: 20px;
        } 
    }

    .list {

        .wrap {
            margin-top: 30px;
        }
        .date {
            margin-bottom: 10px;
        }
        .card {
            background: #EDF6FD;
            padding: 20px 30px;
            border-radius: 10px;
            box-shadow: 0 10px 20px #34416730;
            border: none;

            .head {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                h3 {
                    color: #30426A;
                    font-size: 25px;
                    margin-bottom: 0;
                }
                .actions {
                    
                }
            }
            
        }

        .payment-list {
            border-radius: 0;
            list-style: none;
            padding: 0;

            .item{
                display: flex;
   
                    font-size: 13px;
                    color: #344167;
                
                padding: 0;
                border-bottom: 1px solid #9ABDD2;
                & > div {
                    padding: 10px 0;
                }
            }

            .list-header {
                border: none;
                border-bottom: 1px solid #ae1228;
                background: none;
                color: #344167;
                font-size: 13px;
                margin-bottom: 30px;
                margin-top: 20px;
                padding: 0;
                display: flex;
                & > div {
                    padding: 10px 0;
                }
            }
            


            
        }
    }
}
