// ==========================================
// ================= VARIABLES ==============
// ==========================================
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$dell-red: #ae1228;
$dell-light: #e8e8e8;
$alice-blue: #dbe9f6;
$alice-blue-2: #edf6fd;
$alice-blue-3: #9abdd2;
$dark-cornflower-blue: #30426a;

$spacer: 1rem;
$font-family: 'Interstate', serif;
$shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
$shadow-bottom: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
$shadow-top: rgba(50, 50, 93, 0.25) 0px -6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

// ==========================================
// ================= COLORS =================
// ==========================================
$primary: $dell-red;
$secondary: $alice-blue;
$success: $green;
$info: $alice-blue-3;
$warning: $yellow;
$danger: $red;
$light: $alice-blue-2;
$dark: $dark-cornflower-blue;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'teal': $teal,
  'invalid': $dell-light,
);

$radius: 5px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
